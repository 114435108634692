<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar :title="title" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <van-search
      @search="onSearch"
      v-model="page.filterData.summary"
      placeholder="请输入搜索关键词"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell
            v-for="item in articles"
            :key="item.id.toString()"
            @click="toDetail(item)"
          >
          <div style="display: table-cell; vertical-align: middle" v-if=" item.summary.indexOf('生日')>=0 "> <img src="/image/birthday cake.png" style="max-width: 40px;" ></div>
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >

            
              <h3 style="display: inline-block">{{ item.summary }}</h3>
            </div>
 <van-tag v-if="!item.isRead"
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >最新
                  </van-tag>
            <div style="float: right">{{ item.startTime }}</div>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getSystemMessageList } from "@/api/systemmessage";

//const key = "zn-history";
export default {
  name: "SystemMessage",
  data() {
    return {
      scroll: 0,
      refreshing: false,

      title: "系统通知",

      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          summary: "",
        },
      },
    };
  },
  components: {},
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toDetail(item) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      if (item.linkUrl != undefined && item.linkUrl.length > 0) {
        if (item.linkUrl.indexOf("http") >= 0) {
          window.location.href = item.linkUrl;
        } else {
          this.$router.push({ path: item.linkUrl });
        }
      }
      else{
    

      this.$router.push({
        path: "/user/systemmessagedetail",
        query: { para: item.id, },
      });
      }
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getSystemMessageList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 80px;
  }
}
.zntitle {
  height: 40px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
