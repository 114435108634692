import request from '@/utils/request'

// /api/Product/GetName
export function ReadUpdate() {
  return request({
    url: `/api/SystemMessage/ReadSystemUpdateByUser`
  })
}

export function HasMessage() {
  return request({
    url: `/api/SystemMessage/HasMessage`
  })
}

export function GetSystemUpdate() {
  return request({
    url: `/api/SystemMessage/GetSystemUpdate`
  })
}

// 获取文章详情
export function getSystemMessageInfo(id) {
  return request({
    url: `/api/SystemMessage?id=${id}`
  })
}

//获取首页系统提醒
export function getSystemRemindByUser() {
  return request({
    url: '/api/SystemMessage/GetSystemRemindByUser',
  })
}

export function getSystemMessageList(params) {
  return request({
    url: '/api/SystemMessage/ListByUser',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

//收到系统通知
export function receivedSystemMessage(params) {
  return request({
    url: '/api/SystemMessage/ReceivedSystemMessage',
    data: { ...params },
    method: 'post',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

//获取本部门生日
export function getUserBirthday(orderType,keyWord,moth) {
  return request({
    url: `/api/SystemMessage/GetUserBirthday?orderType=${orderType}&keyWord=${keyWord}&moth=${moth}`
  })
}

//获取本部入职周年
export function getUserCompanyYear(orderType,keyWord,moth) {
  return request({
    url: `/api/SystemMessage/GetUserCompanyYear?orderType=${orderType}&keyWord=${keyWord}&moth=${moth}`
  })
}

